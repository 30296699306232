import { Config } from 'config/index';

export const domain = `${ Config.domain }/api`;

export const application_type = 'CLIENT';

const apiVersion = 'v1';

export const endpoints = {
    auth: {
        register:   `/${ apiVersion }/auth/register`,
        login:      `/${ apiVersion }/auth/login`,
        tokenLogin: `/${ apiVersion }/auth/login/token`,
        otp:        `/${ apiVersion }/auth/otp`,
        resendOtp:  `/${ apiVersion }/auth/otp/send`,
        reset:      `/${ apiVersion }/auth/reset`,
        postReset:  `/${ apiVersion }/auth/reset/send`,
    },
    onBoarding: {
        step1: `/${ apiVersion }/client/onboarding/step1`,
        step2: `/${ apiVersion }/client/onboarding/step2`,
        step3: `/${ apiVersion }/client/onboarding/step3`,
    },
    tags: {
        getAll:      `/${ apiVersion }/client/tags`,
        getSelected: `/${ apiVersion }/client/tags/get`,
        update:      `/${ apiVersion }/client/tags/update`,
    },
    profile: {
        get:            `/${ apiVersion }/client/profile`,
        getInfo:        `/${ apiVersion }/client/profile/info`,
        updateInfo:     `${ apiVersion }/client/profile/info/update`,
        updatePassword: `${ apiVersion }/client/profile/info/update/password`,
        updateAvatar:   `/${ apiVersion }/client/profile/info/update/avatar`,
        deleteAccount: `/${ apiVersion }/client/account/delete`
    },
    referral: {
        get:                         `/${ apiVersion }/client/referral`,
        getNameByCode:               (code) => `/${ apiVersion }/public/client-name/${ code }`,
        getStoreAndCompanyByStoreId: (id) => `/${ apiVersion }/public/store-company/${ id }`,
    },
    levels: {
        get: `/${ apiVersion }/client/profile/levels`,
    },
    transactions: {
        attach: `/${ apiVersion }/client/transaction/attach`,
        update: `/${ apiVersion }/client/transaction/update`,
        buy:    `/${ apiVersion }/client/transaction/buy`,
        verify: `/${ apiVersion }/client/transaction/verification`,
    },
    shop: {
        postShop:         `/${ apiVersion }/client/shop`,
        getVoucherSingle: (id) => `/${ apiVersion }/client/shop/offer/${ id }`,
        postBuy:          `/${ apiVersion }/client/shop/purchase`,
        getBuy:           (token) => `/${ apiVersion }/client/shop/purchase/${ token }`,
    },
    partners: {
        postPartner:      `/${ apiVersion }/client/partners`,
        getPartnerSingle: (id) => `/${ apiVersion }/client/partners/${ id }`,
    },
    points: {
        getPoints:  `/${ apiVersion }/client/points`,
        getHistory: `/${ apiVersion }/client/points/history`,
    },
    wallet: {
        getWallet:        `/${ apiVersion }/client/wallet`,
        history:          `/${ apiVersion }/client/wallet/history`,
        getSingleHistory: (id) => `/${ apiVersion }/client/wallet/${ id }/history`,
        getWalletItem:    (id) => `/${ apiVersion }/client/wallet/${ id }`,
        getExpired:       `/${ apiVersion }/client/wallet/expired`,
    },
    onlineCoupon: {
        partnerUseOnline:  `/${ apiVersion }/client/partners/use-online`,
        offerUseOnline:    `/${ apiVersion }/client/wallet/use-online`,
        requestValidation: `/${ apiVersion }/client/request/validation`,
        getRequestStatus:  (id) => `/${ apiVersion }/client/request/status/${ id }`,
    },
    introTooltip: {
        postView: `/${ apiVersion }/client/tooltip/view`,
    },
    fortuneWheel: {
        checkStatus: `/${ apiVersion }/client/wheel-points/status`,
        play:        `/${ apiVersion }/client/wheel-points/play`,
    },
    fortuneWheelPrime: {
        get:       `/${ apiVersion }/client/wheel-prizes/get`,
        getStatus: `/${ apiVersion }/client/wheel-prizes/status`,
        play:      `/${ apiVersion }/client/wheel-prizes/play`,
    },
    story: {
        getHelpMenu: `/${ apiVersion }/client/help-menu`,
        getStories:  `/${ apiVersion }/client/help/stories`,
        getStory:    (id) => `/${ apiVersion }/client/help-menu/${ id }`,
        setAsViewed: (storyId) => `/${ apiVersion }/client/help/stories/${ storyId }/view`,
    },
    checkout: {
        checkCode: (code) => `/${ apiVersion }/public/clickout/${code}`,
        claim: `/${ apiVersion }/client/clickout/claim`
    }
};
